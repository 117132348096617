import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { FloatLabel } from 'primereact/floatlabel';
import { InputText } from 'primereact/inputtext';
import { useState } from "react";
import {ServerIntf} from './ServerIntf';

const GbbModal = (props) => {

    const handleClose = () => {
        ServerIntf.SetModalState(ServerIntf.ModalType.REPORT, '');
    }

    const nav = (e) => {
        e.preventDefault();
        let anum = document.getElementById('precall-modal-input').value;
        ServerIntf.SetRecall(anum);
        handleClose();
    };

    return (
        <Modal show={props.state.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.state.title}</Modal.Title>
            </Modal.Header>
                {props.state.modalType === ServerIntf.ModalType.REPORT ? (
                    <Modal.Body>
                        <Modal.Title>{props.state.host}</Modal.Title>
                        <Button variant="primary" onClick={() => { ServerIntf.DeckReport(props.state.host)}}>Deck Report</Button>
                        <Button variant="primary" onClick={() => { ServerIntf.SessionReport(props.state.host)}}>Session Report</Button>
                    </Modal.Body>
                ) : (
                    <Modal.Body>
                    <form onSubmit={(e) => {
                    nav(e);
                }}>
            <input id="precall-modal-input" type="text" className="form-control" placeholder="Asset Number"/>
                        </form>
                    </Modal.Body>
                )}
            <Modal.Footer/>
        </Modal>
    );
}

export default GbbModal;