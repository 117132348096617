import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ServerIntf } from './ServerIntf';
import { useState } from "react";
import './App.css';
import './WinOdometer.css';

const Wagers = (props) => {
    const WagerName = (row) => {
        return row.FirstName + " " + row.LastName;
    };

    const RebetSwitch = (wgr) => {
        return (
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" disabled={true} checked={wgr.AutoRebet > 0}/>
            </div>
        );
    };

    const CoverSwitch = (wgr) => {
        return (
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" disabled={true} checked={wgr.AutoCover > 0}/>
            </div>
        );
    };

    const BingoSwitch = (wgr) => {
        return (
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" disabled={true} checked={wgr.AutoBingo > 0}/>
            </div>
        );
    };

    if (props.visible) {
        return (
            <div className="inline-table">
                <div className="table-wrapper">
                    <h4 className="table-name">Unallocated Wagers</h4>
                    <DataTable value={props.unallocWagers} tableStyle={{minWidth: '60rem'}}>
                        <Column header="Name" field={WagerName}></Column>
                        <Column header="Placement Time" field="CreatedAt"></Column>
                        <Column header="Amount" field="Amount"></Column>
                        <Column header="Auto-Cover" body={CoverSwitch}></Column>
                        <Column header="Auto-Rebet" body={RebetSwitch}></Column>
                        <Column header="Auto-Bingo" body={BingoSwitch}></Column>
                    </DataTable>
                </div>
                <div className="table-wrapper">
                    <h4 className="table-name">Allocated Wagers</h4>
                    <DataTable value={props.allocWagers} tableStyle={{minWidth: '60rem'}}>
                        <Column header="Agent" field="ESS"></Column>
                        <Column header="Name" field={WagerName}></Column>
                        <Column header="Placement Time" field="CreatedAt"></Column>
                        <Column header="Amount" field="Amount"></Column>
                        <Column header="Auto-Cover" body={CoverSwitch}></Column>
                        <Column header="Auto-Rebet" body={RebetSwitch}></Column>
                        <Column header="Auto-Bingo" body={BingoSwitch}></Column>
                        <Column header="Activation Time" field="AssignedAt"></Column>
                    </DataTable>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default Wagers;