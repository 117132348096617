import axios from 'axios';
import {BehaviorSubject} from 'rxjs';
import {NotificationManager} from 'react-notifications';

const FileDownload = require('js-file-download');

const ModalType = Object.freeze({
    PRECALL: 0,
    REPORT: 1
});

const serverIntf = (function () {
    let once = false;
    let isWaiting = false;
    let api;
    let retStruct;

    // enrollments functions 
    let enroll;
    let enable;
    let unenroll;
    let ignoreEss;
    let navRules;

    // Context handling
    let context;
    let contextSubject;
    let contextSubjectSubscribe;

    // Pinwheel handling
    let waitingSubject;
    let waitingSubjectSubscribe;

    // Modal handling
    let modalSubj;
    let modalSubjSubscrbr;
    let setModalState;

    // Precall handling
    let precallSubj;
    let precallSubjSubscrbr;

    // ESS handling
    let ess;
    let essSubject;
    let essSubjectSubscribe;
    let setESS;

    // Websocket
    let ws;

    // Egm handling
    let egmSubject;
    let egmSubjectSubscribe;

    // Recall handling
    let recallSubject;
    let recallSubjectSubscribe;
    let recall;
    let setRecall;

    // Login handling
    let usr;
    let login;
    let usermod;
    let loginSubject;
    let loginSubjectSubscribe;

    // Wager handling
    let getWagers;
    let wagerSubject;
    let wagerSubjectSubscribe;

    // Generic method
    let get;
    let post;

    // report handling
    let sessionReport;
    let enrollmentReport;
    let deckReport;
    let auditReport;

    // Initialization login to publish the login state from server
    const initlogin = () => {
        loginSubject = new BehaviorSubject({success: false, admin: false});
        loginSubjectSubscribe = (subscriber) => {
            return loginSubject.subscribe(subscriber);
        };

        waitingSubject = new BehaviorSubject(false);
        waitingSubjectSubscribe = (subscriber) => {
            return waitingSubject.subscribe(subscriber);
        };

        login = (user, pass) => {
            waitingSubject.next(true);
            return api.post('/login', {}, {auth: {username: user, password: pass}})
                .then((response) => {
                    usr = response.data;
                    loginSubject.next({success: true, admin: response.data.Admin});
                    waitingSubject.next(false);
                    activateWs();
                }).catch(err => {
                    parseError(err, 1500);
                    user = null;
                    loginSubject.next(false);
                    waitingSubject.next(false);
                    throw (err);
                });
        };

        usermod = (user, email, lic, pass) => {
            if (lic !== '') {
                let usr = {
                    username: user,
                    email: email,
                    license: lic,
                    password: pass
                };
                waitingSubject.next(true);
                return api.post('/register', usr)
                    .then(() => {
                        waitingSubject.next(false);
                        window.location.pathname = '/';
                    }).catch(err => {
                        parseError(err, 1500);
                        waitingSubject.next(false);
                        throw (err);
                    });
            } else {
                waitingSubject.next(true);
                return api.post('/password-reset', {}, {auth: {username: user, password: pass}})
                    .then(() => {
                        waitingSubject.next(false);
                        window.location.pathname = '/';
                    }).catch(err => {
                        parseError(err, 1500);
                        waitingSubject.next(false);
                        throw (err);
                    });

            }
        };

        const request = (url, data, method, isBlob, background) => {
            waitingSubject.next(!background);
            let config = {
                url: url,
                data: data,
                method: method,
            };

            if (isBlob)
                config.responseType = 'blob';

            if (usr) {
                if (usr.Token)
                    config.headers = {'Authorization': usr.Token};
            }

            return api.request(config).catch((error) => {
                if (error && error.response) {
                    if (error.response.status === 401 || error.response.status === 403) {
                        if (usr.Token)
                            signoff();
                    }
                }
                throw (error);
            });
        };

        get = (url, isBlob, background) => {
            return request(url, null, 'get', isBlob, background);
        };

        post = (url, data, background) => {
            if (usr.Admin) {
                return request(url, data, 'post', background);
            }

            throw new Error("You do no have sufficient privileges to perform machine configuration");
        };

        enroll = (_ess) => {
            post('/enrollment/enroll/' + _ess).then((response) => {
                waitingSubject.next(false);
                return response;
            }).catch(err => {
                waitingSubject.next(false);
                parseError(err);
                return null;
            });
        };

        enable = (_ess) => {
            post('/enrollment/enable/' + _ess).then((response) => {
                waitingSubject.next(false);
                return response;
            }).catch(err => {
                waitingSubject.next(false);
                parseError(err);
                return null;
            });
        };

        unenroll = (_ess) => {
            post('/enrollment/unenroll/' + _ess).then((response) => {
                waitingSubject.next(false);
                return response;
            }).catch(err => {
                waitingSubject.next(false);
                parseError(err);
                return null;
            });
        };

        ignoreEss = (_ess) => {
            return post('/enrollment/ignore/' + _ess);
        };

        setModalState = (type, _arg) => {
            if(type === ModalType.REPORT && _arg !== '') {
                modalSubj.next({ show: true, host: _arg, title: 'Download Host Report', modalType: type });
            } else if(type === ModalType.PRECALL && _arg > 0) {
                modalSubj.next({show: true, host: _arg, title: 'Egm Recall Preview', modalType: type});
            } else {
                modalSubj.next({show: false, host: '', title: 'Download Host Report', modalType: ModalType.REPORT});
            }
        }

        // reporting
        const downloadReport = (url, name) => {
            waitingSubject.next(true);
            let config = {
                responseType: 'blob',
                headers: {'Authorization': usr.Token}
            };

            return api.get(url, config, {}).then(res => {
                FileDownload(res.data, name);
                waitingSubject.next(false);
            }).catch((error) => {
                if (error && error.response) {
                    if (error.response.status === 401 || error.response.status === 403) {
                        if (usr.Token) signoff();
                    }
                }
                parseError(error, 1500);
                throw (error);
            });
        };

        sessionReport = (host) => {
            return downloadReport('/reports/sessions?host=' + host, 'session.csv');
        };

        enrollmentReport = () => {
            return downloadReport('/reports/enrollment', 'enrollment.csv');
        };

        deckReport = (host) => {
            return downloadReport('/reports/deck?host=' + host, 'deck.csv');
        };

        auditReport = () => {
            return downloadReport('/reports/audits', 'audits.csv');
        }

        getWagers = () => {
            let config = {
                responseType: 'json',
                headers: {'Authorization': usr.Token}
            };
            return api.get('/wagers', config, {}).then((response) => {
                wagerSubject.next(response.data);
            }).catch(err => {
                parseError(err, 1500);
            });
        }
    };

    const activateWs = () => {
        ws = new WebSocket('wss://' + window.location.host + '/ws/games');
        ws.onmessage = function (event) {
            if (event.data) {
                let dat = JSON.parse(event.data);
                egmSubject.next(dat);
                if(ess && ess !== '') {
                    for(let i = 0; i < dat.length; i++) {
                        if(dat[i].ESS === ess) {
                            recallSubject.next(dat[i]);
                        }
                    }
                }
            }
        };
        ws.onclose = function (evt) {
            console.log("Websocket closed: " + JSON.stringify(evt, null, 2));
            info('Reconnecting...', 'Re-establishing connection to websocket', 1500);
            setTimeout(activateWs(), 1500);
        };
        ws.onerror = function (evt) {
            console.log("Websocket error: " + JSON.stringify(evt, null, 2));
            setTimeout(activateWs(), 1500);
        };
    };

    // Initialization configSubject to publish the app config from server
    const initContext = () => {
        contextSubject = new BehaviorSubject({ Version: "unknown" });
        contextSubjectSubscribe = (subscriber) => {
            return contextSubject.subscribe(subscriber);
        };

        return api.get('/context').then((response) => {
            contextSubject.next(response.data);
            context = response.data;
        }).catch(err => {
            parseError(err, 1500);
            setTimeout(initContext, 2000);
        });
    };

    // Initialization code to startup server interface. This should be called at most once
    // which we ensure by returning a function 
    const init = () => {
        api = axios.create({
            baseURL: ('https://' + window.location.hostname) + "/api",
            timeout: 30000,
        });

        // Pinwheel handling
        waitingSubject = new BehaviorSubject(false);
        waitingSubjectSubscribe = (subscriber) => {
            return waitingSubject.subscribe(subscriber);
        };
        waitingSubject.subscribe((_isWaiting) => {
            isWaiting = _isWaiting;
        });

        // Precall handling
        precallSubj = new BehaviorSubject('');
        precallSubjSubscrbr = (subscriber) => {
            return precallSubj.subscribe(subscriber);
        }

        // Games handling
        egmSubject = new BehaviorSubject([]);
        egmSubjectSubscribe = (subscriber) => {
            return egmSubject.subscribe(subscriber);
        };

        // Wager handling
        wagerSubject = new BehaviorSubject([])
        wagerSubjectSubscribe = (subscriber) => {
            return wagerSubject.subscribe(subscriber);
        }

        // Recall handling
        recallSubject = new BehaviorSubject({});
        recallSubjectSubscribe = (subscriber) => {
            return recallSubject.subscribe(subscriber);
        };
        recallSubject.subscribe((_recall) => {
            recall = _recall;
        });
        setRecall = (anum) => {
            if (anum > 0) {
                get('/peek/' + anum, false, true).then((res) => {
                    if (recall !== res.data) {
                        recallSubject.next({ GameRecallJson: res.data });
                        precallSubj.next(true);
                    }
                }).catch(err => {
                    parseError(err, 1500);
                });
            } else {
                recallSubject.next({});
                precallSubj.next(false);
            }
        }

        // ess handling
        essSubject = new BehaviorSubject('');
        essSubjectSubscribe = (subscriber) => {
            return essSubject.subscribe(subscriber);
        };
        essSubject.subscribe((_ess) => {
            ess = _ess;
        });
        setESS = (_ess) => {
            if (_ess !== ess) {
                if (_ess === '') {
                    recallSubject.next([]);
                }
                essSubject.next(_ess);
            }
        }

        // Report Modal handling
        modalSubj = new BehaviorSubject('');
        modalSubjSubscrbr = (subscriber) => {
            return modalSubj.subscribe(subscriber);
        };

        initContext();
        initlogin(); // DO this last so all subjects are defined
    };

    const notify = (typ, title, body, duration) => {
        try {
            title = title || "Unknown";
            body = body || "";
            duration = duration || 1500;
            NotificationManager[typ](body, title, duration);
        } catch (error) {
            console.log(error.data);
        }
    };

    const info = (title, body, duration) => {
        notify('info', title, body, duration);
    };
    const success = (title, body, duration) => {
        notify('success', title, body, duration);
    };
    const warning = (title, body, duration) => {
        notify('warning', title, body, duration);
    };
    const error = (title, body, duration) => {
        notify('error', title, body, duration);
    };

    const show = (err, nf, duration) => {
        let title = "Unknown Error";
        let body = "";

        try {
            if (err) {
                if (err.response && err.response.statusText) {
                    title = err.response.statusText;
                } else {
                    if (err.message) title = err.message;
                }
                if (err.response && err.response.data) body = err.response.data;
            }

            nf(title, body, duration || 1500);
        } catch (error) {
            console.log(error.data);
        }
    };

    const parseError = (err, duration) => {
        show(err, error, duration);
    };

    const parseWarning = (err, duration) => {
        show(err, warning, duration);
    };

    const signoff = () => {
        usr = null;
        loginSubject.next(false);
        waitingSubject.next(false);
    };

    const initRetStruct = () => {
        retStruct = {
            SubscribeContext: contextSubjectSubscribe,
            SubscribeLogin: loginSubjectSubscribe,
            SubscribeWaiting: waitingSubjectSubscribe,
            SubscribePrecall: precallSubjSubscrbr,
            SubscribeESS: essSubjectSubscribe,
            SubscribeEgms: egmSubjectSubscribe,
            SubscribeRecall: recallSubjectSubscribe,
            SubscribeModal: modalSubjSubscrbr,
            SubscribeWagers: wagerSubjectSubscribe,
            ModalType: ModalType,
            SetModalState: setModalState,
            Login: login,
            Register: usermod,
            Get: get,
            Post: post,
            SetESS: setESS,
            SetRecall: setRecall,
            NavRules: navRules,
            Enroll: enroll,
            Enable: enable,
            UnEnroll: unenroll,
            Ignore: ignoreEss,
            GetWagers: getWagers,
            ParseError: parseError,
            ParseWarning: parseWarning,
            Info: info,
            Success: success,
            Warning: warning,
            Error: error,
            SignOff: signoff,
            SessionReport: sessionReport,
            EnrollmentReport: enrollmentReport,
            DeckReport: deckReport,
            AuditReport: auditReport,
        }
    };

    return function () {
        if (!once) {
            once = true;
            isWaiting = false;
            init();
            initRetStruct();
        }
        return retStruct;
    };
})();

export const ServerIntf = serverIntf();

