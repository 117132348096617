import './App.css';
import './WinOdometer.css';
import Odometer from 'react-odometerjs';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ServerIntf } from './ServerIntf';
import {useState} from "react";

const NonEgms = (props) => {
    const essSelected = (ess) => {
        ServerIntf.SetESS(ess);
    };

    const WinOdometer = (row) => {
        return <Odometer value={row.EndPrizeWins} duration={500}/>;
    };

    const shortESS = (row) => {
        return row.ESS.split("::")[1];
    };

    const RecallSwitch = (row) => {
        let selected = row.ESS === props.ess;
        return (
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch"
                       checked={selected}
                       onChange={() => essSelected(selected ? '' : row.ESS)}/>
            </div>
        );
    };

    const hostHeader = (data) => {
        return (
            <div className="flex align-items-center gap-2"><span className="font-bold">{data.Hostname}</span></div>
        );
    };

    if (props.visible) {
        return (
            <div className="table-wrapper">
                <h4 className="table-name">Non EGM Players</h4>
                <DataTable value={props.players.filter((player) => player.ESS.includes("::"))}
                           stripedRows showGridlines columnResizeMode="expand" rowGroupMode="subheader" size="small"
                           groupRowsBy="Hostname" rowGroupHeaderTemplate={hostHeader} scrollable scrollHeight="200px"
                           tableStyle={{minWidth: '30rem'}}>
                    <Column field={shortESS} header="ID"></Column>
                    <Column field="Alias" header="Name"></Column>
                    <Column field="EndPrizeWins" header="Wins" body={WinOdometer}></Column>
                    <Column header="Recall?" body={RecallSwitch}></Column>
                </DataTable>
            </div>
        );
    } else {
        return null;
    }
}

export default NonEgms;
