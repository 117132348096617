import './App.css';
import './WinOdometer.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Odometer from 'react-odometerjs';
import {useState} from "react";
import {ServerIntf} from './ServerIntf'

const FilterType = Object.freeze({
    FLOOR_LOC: 0,
    ASSET_NUM: 1,
    HOST_ID: 2,
});

const Egms = (props) => {
    const [filterVal, setFilterVal] = useState("");
    const [filterType, setFilterType] = useState(FilterType.FLOOR_LOC);
    const [selectedRow, setSelectedRow] = useState(null);

    const essSelected = (ess) => {
        ServerIntf.SetESS(ess);
    };

    const onSelectRow = (row) => {
        setSelectedRow(row);
        ServerIntf.SetModalState(ServerIntf.ModalType.REPORT, row.Hostname);
    };

    const smolStr = (txt) => {
        return txt.toString().toLowerCase()
    }

    const filterMatch = (game) => {
        switch (filterType) {
            case FilterType.FLOOR_LOC:
                return smolStr(game.FloorLocation.split(":").filter(Boolean).join("-")).includes(smolStr(filterVal))
            case FilterType.ASSET_NUM:
                return smolStr(game.AssetNumber).includes(smolStr(filterVal))
            case FilterType.HOST_ID:
                return smolStr(game.Hostname).includes(smolStr(filterVal))
            default:
                return
        }
    };

    const enrollChange = (sw, egm) => {
        let ef = ServerIntf.Enroll;
        if (sw === 0) {
            if (egm.EnrolledState > 0) ef = ServerIntf.UnEnroll;
        } else {
            if (egm.EnrolledState < 2) ef = ServerIntf.Enable;
        }
        ef(egm.ESS);
    };

    const ignoreEGM = (egm) => {
        ServerIntf.Ignore(egm.ESS);
    };

    const floorLocation = (row) => {
        return row.FloorLocation.split(":").filter(Boolean).join("-");
    };

    const WinOdometer = (row) => {
        return <Odometer value={row.EndPrizeWins} duration={500}/>;
    };

    const EnrolledSwitch = (row) => {
        return (
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch"
                       checked={row.EnrolledState > 0} onChange={() => enrollChange(0, row)}/>
            </div>
        );
    };

    const EnabledSwitch = (row) => {
        return (
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch"
                       checked={row.EnrolledState === 2} onChange={() => enrollChange(1, row)}/>
            </div>
        );
    };

    const RecallSwitch = (row) => {
        let selected = row.ESS === props.ess;
        return (
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" checked={selected}
                       onChange={() => essSelected(selected ? '' : row.ESS)}/>
            </div>
        );
    };

    const IgnoreEgmBtn = (row) => {
        return (
            <div className=''>
                <input className="" type="button" value="Remove" onClick={() => ignoreEGM(row)}/>
            </div>
        );
    }

    if (props.visible) {
        return (
            <div className="table-wrapper">
                <h4 className="table-name">EGM Players</h4>
                <div className='container'>
                    <div className="row">
                        <div className="col">
                            <input type="text" className="form-control" placeholder="Location"
                                   onChange={(e) => {
                                       setFilterVal(e.target.value);
                                       setFilterType(FilterType.FLOOR_LOC);
                                   }}/>
                        </div>
                        <div className="col">
                            <input type="text" className="form-control" placeholder="Asset"
                                   onChange={(e) => {
                                       setFilterVal(e.target.value);
                                       setFilterType(FilterType.ASSET_NUM);
                                   }}/>
                        </div>
                        <div className="col">
                            <input type="text" className="form-control" placeholder="Host"
                                   onChange={(e) => {
                                       setFilterVal(e.target.value);
                                       setFilterType(FilterType.HOST_ID);
                                   }}/>
                        </div>
                    </div>
                </div>
                <DataTable
                    value={props.egms.filter((egm) => (!filterVal.length || filterMatch(egm)) && !egm.ESS.includes("::") && egm.IgnoredState === 0)}
                    stripedRows showGridlines columnResizeMode="expand" dataKey="ESS" scrollable selectionMode="single"
                    selection={selectedRow} onSelectionChange={(e) => onSelectRow(e.value)} scrollHeight="400px"
                    tableStyle={{minWidth: '50rem'}}>
                    <Column field={floorLocation} header="Location"></Column>
                    <Column field="AssetNumber" header="Asset Number"></Column>
                    <Column field="Hostname" header="Host"></Column>
                    <Column field="ESS" header="Extended SAS Serial"></Column>
                    <Column header="Wins" body={WinOdometer}></Column>
                    <Column header="Enrolled" body={EnrolledSwitch}></Column>
                    <Column header="Enabled" body={EnabledSwitch}></Column>
                    <Column header="Recall" body={RecallSwitch}></Column>
                    <Column header="Remove" body={IgnoreEgmBtn}></Column>
                </DataTable>
            </div>
        );
    } else {
        return null;
    }
}

export default Egms;