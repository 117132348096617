/*jshint esversion: 6 */
import React, {useEffect, useState} from "react";
import {ServerIntf} from './ServerIntf';
import {Login} from './Login';
import Register from './Register';
import {Rules} from "./Rules";
import PasswordReset from "./PasswordReset";
import NonEgms from './NonEgms';
import Egms from './Egms';
import GameRecall from './GameRecall';
import GbbModal from './GbbModal';
import Wagers from './Wagers';
import {NotificationContainer} from 'react-notifications';
import {ColorRing} from 'react-loader-spinner';
import './App.css';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);
    const [isPrecall, setIsPrecall] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [rulesVisible, setRulesVisibility] = useState(false);
    const [egmsVisible, setEgmsVisibility] = useState(true);
    const [nonEgmsVisible, setNonEgmsVisibility] = useState(true);
    const [recallVisible, setRecallVisibility] = useState(true);
    const [wagersVisible, setWagersVisibility] = useState(false);
    const [context, setContext] = useState({ Version: 'unknown', SHA: 'not set' });
    const [recall, setRecall] = useState({FloorLocation: null, Games:[]});
    const [modalState, setModalState] = useState({ show: false, host: '', title: 'Download Host Report', modalType: ServerIntf.ModalType.REPORT });
    const [egms, setEgms] = useState([]);
    const [unallocated, setUnallocated] = useState([]);
    const [allocated, setAllocated] = useState([]);
    const [ess, setESS] = useState('');

    useEffect(() => {
        let contextSubscriber = ServerIntf.SubscribeContext((v) => setContext(v));
        let waitingSubscriber = ServerIntf.SubscribeWaiting((v) => setIsWaiting(v));
        let precallSubscriber = ServerIntf.SubscribePrecall((v) => setIsPrecall(v));
        let essSubscriber = ServerIntf.SubscribeESS((v) => setESS(v));
        let egmsSubscriber = ServerIntf.SubscribeEgms((v) => setEgms(v));
        let recallSubscriber = ServerIntf.SubscribeRecall((v) => setRecall(v));
        let modalSubscriber = ServerIntf.SubscribeModal((v) => setModalState(v));
        let loginSubscriber = ServerIntf.SubscribeLogin((v) => {
            setIsLoggedIn(v.success);
            setIsAdmin(v.admin);
        });
        let wagerSubscriber = ServerIntf.SubscribeWagers((v) => {
            setUnallocated(v.filter(w => w.ESS === ""));
            setAllocated(v.filter(w => w.ESS !== ""));
        });

        return function () {
            contextSubscriber.unsubscribe();
            loginSubscriber.unsubscribe();
            waitingSubscriber.unsubscribe();
            precallSubscriber.unsubscribe();
            essSubscriber.unsubscribe();
            egmsSubscriber.unsubscribe();
            recallSubscriber.unsubscribe();
            modalSubscriber.unsubscribe();
            wagerSubscriber.unsubscribe();
        }
    }, []);

    return (
        <div id="App" className="App">
            <nav className="navbar navbar-dark justify-content-between">
                <div className="navbar-center">
                    <a href="/" style={{textDecoration: 'none'}}><span className="navbar-brand abs">Grab Bag Bingo</span></a>
                </div>
                <div className="navbar-center">
                    <span className="navbar-text">{context.SHA}</span>
                </div>
                <div className="navbar-right">
                    {isLoggedIn && <button className="nav-link-button" onClick={ServerIntf.EnrollmentReport}>Enrollment Report</button>}
                    {isLoggedIn ? (
                        <button className="nav-link-button" onClick={ServerIntf.AuditReport}>Audit Report</button>
                    ) : (
                        <button className="nav-link-button" onClick={() => {
                            ServerIntf.SetModalState(ServerIntf.ModalType.PRECALL, 1);
                        }}>Preview Recall</button>
                    )}
                    {isAdmin && <button className="nav-link-button" onClick={() => {
                        setEgmsVisibility(false);
                        setRecallVisibility(false);
                        setNonEgmsVisibility(true);
                        setWagersVisibility(true);
                    }}>Wagers</button>}
                    <button className="nav-link-button" onClick={() => setRulesVisibility(true)}>Rules</button>
                    {isLoggedIn && <button className="nav-link-button" onClick={ServerIntf.SignOff}>SignOff</button>}
                </div>
            </nav>
            {isLoggedIn ? (
                <div className='container'>
                    <h3>Grab Bag Bingo Casino Management Tool</h3>
                    <Egms visible={egmsVisible} ess={ess} egms={egms}/>
                    <NonEgms visible={nonEgmsVisible} ess={ess} players={egms}/>
                    <GameRecall visible={recallVisible} ess={ess} recall={recall}/>
                    <Wagers visible={wagersVisible} unallocWagers={unallocated} allocWagers={allocated}/>
                </div>
            ) : window.location.pathname === "/Register" ? (
                <Register/>
            ) : window.location.pathname === "/PasswordReset" ? (
                <PasswordReset/>
            ) : isPrecall ? (
                <div className='container'>
                    <div className='container'><GameRecall ess={'preview'} recall={recall}/></div>
                </div>
            ) : (
                <Login/>
            )}
            <ColorRing visible={isWaiting} height="80" width="80" ariaLabel="blocks-loading"
                       wrapperClass={"loading-ring"} colors={['#94f503', '#5ef327', '#16f607', '#15860e', '#849b87']}/>
            <GbbModal state={modalState}/>
            <Rules visible={rulesVisible} onhide={() => setRulesVisibility(false)}/>
            <NotificationContainer/>
        </div>
    );
}

export default App;

// <Management />